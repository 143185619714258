import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HotelRepository } from '../domain/Hotel.repository'
import { NearbyHotel } from '../domain/Hotel.model'
import { NearbyHotelsCriteria } from '../domain/NearbyHotelsCriteria'
import { envManager } from 'src/core/Shared/infrastructure/envManager'
import { JoeDispenzaHotels } from 'src/core/Shared/domain/EnvManager'

export const getNearbyHotels =
  ({ hotelRepository }: Dependencies) =>
  async (
    request: NearbyHotelsCriteria,
    coupon?: string,
  ): Promise<NearbyHotel[] | undefined> => {
    const nearbyHotels = await hotelRepository.getNearbyHotels(request)
    const joeDispenzaEvents = envManager.getJoeDispenzaEvents()

    if (isUndefined(nearbyHotels) || isEmpty(nearbyHotels)) {
      return undefined
    }

    // TODO: Lógica implementada para el evento Joe Dispenza, se eliminará en el futuro
    if (isDefined(coupon) && isDefined(joeDispenzaEvents[coupon])) {
      const JDHotels = filterJoeDispenzaHotels(
        nearbyHotels,
        joeDispenzaEvents[coupon],
      )

      if (isEmpty(JDHotels)) {
        return undefined
      }

      return JDHotels
    }

    return nearbyHotels
  }

function filterJoeDispenzaHotels(
  nearbyHotels: NearbyHotel[],
  joeDispenzaHotels: JoeDispenzaHotels,
) {
  return nearbyHotels.filter(hotel => joeDispenzaHotels.includes(hotel.id))
}

interface Dependencies {
  hotelRepository: HotelRepository
}
